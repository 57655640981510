export const firebaseConfig = {
  apiKey: "AIzaSyBUW-OPaBcmTBB6AFXcoKT_inTm5uMB54E",
  authDomain: "kochi-bus-photo.firebaseapp.com",
  projectId: "kochi-bus-photo",
  storageBucket: "kochi-bus-photo.appspot.com",
  messagingSenderId: "945481223191",
  appId: "1:945481223191:web:b11fef6cc208b5683f2052",
  measurementId: "G-7XN3TB5197",
};

export const env = "dev";
